<template>
  <div
    id="interface"
    class="Interface"
    @click="activity"
  >
    <transition-group tag="div" name="fade" class="Splitter">
      <Status v-if="screenMode.mode !== 'asleep'"
              key="status"
              class="Status" :expanded="menuExpanded"
              :screen-mode="screenMode"
      />
      <!--
      <Desktop
        v-if="screenMode.mode === 'awake'"
        key="desktop"
        :app-launcher-visible="appLauncherVisible"
        class="Desktop"
        @hide-launcher="closeAppList"
        @activity="activity"
      /> -->
    </transition-group>
  </div>
</template>

<script>
import Status from "./components/Status.vue";
// import Desktop from "./components/Desktop.vue";

export default {
  name: "Interface",
  components: {
    Status,
    // Desktop,
  },
  props: {
    device: {
      type: String,
      default: "none",
    },
    screenMode: {
      type: Object,
      required: true,
    },
    theme: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      indicatorsVisible: false,
      menuExpanded: false,
      appLauncherVisible: false,
    };
  },
  computed: {
    contentClass() {
      return {
        Splitter: true,
        "is-scrolling": this.contentScrolling,
      };
    },
  },
  mounted() {},
  methods: {
    deviceChanged() {
      this.$refs.mirror.reload();
      this.$refs.splitter.setup();
    },

    setExpanded(bool) {
      this.menuExpanded = bool;
    },

    activity() {
      this.$emit("activity");
    },

    clickedLauncher() {
      this.appLauncherVisible = !this.appLauncherVisible;
    },
    closeAppList() {
      this.appLauncherVisible = false;
    },
    clickedSwitcher() {
      this.setExpanded(!this.menuExpanded);
    },
  },
};
</script>
<style scoped lang="scss">
.Splitter {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 1rem;
  color: var(--color-primary);
  display: grid;
  grid-template-rows: auto 1fr;
  /* height: calc(100% - 2.5rem); // WebKit fix? */
}
.Status {
  /* padding: 1rem 1rem 1rem 1rem; */
  width: 100%;
}
.Launcher {
  margin: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1.5s ease;
}
.fade-leave-to, .fade-enter {
  opacity: 0;
}

.Desktop.fade-enter-active {
  transition-delay: 1.5s;
}
</style>
