import StaticGrid from '@/../api/v3/StaticGrid';
import Mirror from '@/../api/v3/Mirror';

export default function(lineColor) {
  return {
    Mirror: {
      class: Mirror,
      settings: {},
      params: {
        offsetX: 0,
        offsetY: 0,
        // lineColor: "#000",
        lineColor,
        lineWeight: 0.25,
        maxLineWeight: 0.25,
        weightVariation: true,
        horizMovement: 1,
        vertMovement: 0,
        inactivationThreshold: 1,
        sensibility: 20,
        scale: 1,
        cellW: 30,
        cellH: 180,
        linePadding: 2,
      },
    }
  };
};
