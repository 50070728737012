<template>
  <div @click="clicked">
    <div class="ClockDisplay">
      <div ref="now" class="Now">
        <span class="Time">
          {{ time }}
        </span>
        <span class="Date">
          {{date}}
        </span>
      </div>
    </div>
    <!-- <div class="NotificationPill" /> -->
  </div>
</template>

<script>
import gsap from "gsap";

export default {
  name: "Clock",
  data() {
    return {
      now: new Date(),
    };
  },
  computed: {
    time() {
      return this.now.toLocaleTimeString("fr", {
        hour: "2-digit",
        minute: "2-digit",
      });
    },
    date() {
      return this.now.toLocaleDateString("en-GB", {
        weekday: "long",
        day: "numeric",
        month: "numeric",
      }).replace("/", ".");
    }
  },
  mounted() {
    window.setInterval(() => {
      this.now = new Date();
    }, 1000);
  },
  methods: {
    clicked() {
      this.$emit("clicked");
    },
  },
};
</script>

<style scoped lang="scss">
.Clock {
  position: relative;
  color: var(--color-primary);
  display: flex;
  align-self: start;

  --clock-font-size: clamp(5rem, 20vh, 10rem);
}
.Now {
  font-size: var(--clock-font-size);
  font-weight: 200;
  display: flex;
  align-items: baseline;
}
.Date {
  margin-left: 2rem;
  font-size: calc(var(--clock-font-size) / 5.0);
}

.NotificationPill {
  position: relative;
  height: .5rem;
  width: .5rem;
  top: .5rem;
  background: var(--color-secondary);
  border-radius: 1rem;
  margin-left: .25rem;
}
</style>
