// import whiteOnBlue from "@/configurations/white-on-blue";
// import blueOnBlue from "@/configurations/blue-on-blue";
// import yellowOnGreen from "@/configurations/yellow-on-green";
// import redOnRed from "@/configurations/red-on-red";

import tileRed   from "@/configurations/tile-red";
import tileGreen from "@/configurations/tile-green";
import tilePale  from "@/configurations/tile-pale";

const Themes = Object.freeze({
  rouge: Object.freeze(tileRed),
  pale: Object.freeze(tilePale),
  vert: Object.freeze(tileGreen),
});

function setTheme(theme) {
  this.$emit("set-theme", theme);
}

export { setTheme };
export default Themes;
