<template>
  <div :class="componentClass">

    <svg hidden>
      <symbol id="tile-chat" viewBox="0 0 42 38">
          <g transform="matrix(0.381022,0,0,0.324524,-47.4225,-203.241)">
              <g id="tile-chat1" serif:id="tile-chat">
                  <g transform="matrix(10.8736,0,0,12.7667,209.986,684.686)">
                      <path d="M0,0.137C-0.504,0.137 -0.912,-0.271 -0.912,-0.775C-0.912,-1.279 -0.504,-1.687 0,-1.687C0.504,-1.687 0.912,-1.279 0.912,-0.775C0.912,-0.271 0.504,0.137 0,0.137M-2.66,0.137C-3.164,0.137 -3.572,-0.271 -3.572,-0.775C-3.572,-1.279 -3.164,-1.687 -2.66,-1.687C-2.156,-1.687 -1.748,-1.279 -1.748,-0.775C-1.748,-0.271 -2.156,0.137 -2.66,0.137M-5.32,0.137C-5.824,0.137 -6.232,-0.271 -6.232,-0.775C-6.232,-1.279 -5.824,-1.687 -5.32,-1.687C-4.816,-1.687 -4.408,-1.279 -4.408,-0.775C-4.408,-0.271 -4.816,0.137 -5.32,0.137M0.558,-4.334L-5.91,-4.334C-6.857,-4.334 -7.624,-3.566 -7.624,-2.619L-7.624,1.199C-7.624,2.145 -6.857,2.913 -5.91,2.913L-1.36,2.913L0.187,4.471L0.187,2.913L0.558,2.913C1.504,2.913 2.272,2.145 2.272,1.199L2.272,-2.619C2.272,-3.566 1.504,-4.334 0.558,-4.334" style="fill-rule:nonzero;"/>
                  </g>
              </g>
          </g>
      </symbol>
      <symbol id="tile-mail" viewBox="0 0 42 38">
          <g transform="matrix(1,0,0,1,-122.448,0)">
              <g transform="matrix(0.381022,0,0,0.324524,75.0254,-203.241)">
                  <g id="tile-mail1" serif:id="tile-mail">
                      <g transform="matrix(10.8309,0,0,12.7166,221.097,706.088)">
                          <path d="M0,-3.466L-3.535,-0.606C-3.72,-0.457 -3.984,-0.457 -4.168,-0.608L-7.669,-3.466C-7.884,-3.641 -7.916,-3.957 -7.74,-4.171C-7.565,-4.386 -7.249,-4.418 -7.035,-4.243L-4.165,-1.9C-3.981,-1.75 -3.717,-1.749 -3.532,-1.899L-0.631,-4.246C-0.416,-4.42 -0.1,-4.387 0.075,-4.171C0.249,-3.956 0.215,-3.64 0,-3.466M0.462,-5.298L-8.129,-5.298C-8.5,-5.298 -8.801,-4.997 -8.801,-4.626L-8.801,1.16C-8.801,1.531 -8.5,1.832 -8.129,1.832L0.462,1.832C0.833,1.832 1.134,1.531 1.134,1.16L1.134,-4.626C1.134,-4.997 0.833,-5.298 0.462,-5.298" style="fill-rule:nonzero;"/>
                      </g>
                  </g>
              </g>
          </g>
      </symbol>

      <symbol id="tile-photo" viewBox="0 0 50 38">
          <g transform="matrix(1,0,0,1,-182.176,0)">
              <g transform="matrix(0.453597,0,0,0.324524,125.721,-203.241)">
                  <g id="tile-photo1" serif:id="tile-photo">
                      <g transform="matrix(9.10286,0,0,12.7234,210.858,695.642)">
                          <path d="M0,-1.822C-0.617,-1.822 -1.117,-2.322 -1.117,-2.939C-1.117,-3.555 -0.617,-4.055 0,-4.055C0.617,-4.055 1.117,-3.555 1.117,-2.939C1.117,-2.322 0.617,-1.822 0,-1.822M-3.437,1.078C-4.524,1.078 -5.405,0.197 -5.405,-0.89C-5.405,-1.977 -4.524,-2.859 -3.437,-2.859C-2.349,-2.859 -1.468,-1.977 -1.468,-0.89C-1.468,0.197 -2.349,1.078 -3.437,1.078M0.73,-5.101L-7.603,-5.101C-8.513,-5.101 -9.249,-4.364 -9.249,-3.455L-9.249,1.633C-9.249,2.542 -8.513,3.279 -7.603,3.279L0.73,3.279C1.639,3.279 2.376,2.542 2.376,1.633L2.376,-3.455C2.376,-4.364 1.639,-5.101 0.73,-5.101" style="fill-rule:nonzero;"/>
                      </g>
                  </g>
              </g>
          </g>
      </symbol>

      <symbol id="tile-phone" viewBox="0 0 42 38">
          <g transform="matrix(1,0,0,1,-64.3424,0)">
              <g transform="matrix(0.381022,0,0,0.324524,16.9199,-203.241)">
                  <g id="tile-phone1" serif:id="tile-phone">
                      <g transform="matrix(10.8357,0,0,12.7221,173.801,696.785)">
                          <path d="M0,-2.002C0,-2.418 0.337,-2.754 0.752,-2.754L2.082,-2.754C2.498,-2.754 2.834,-3.091 2.834,-3.507L2.834,-4.749C2.834,-5.164 2.498,-5.501 2.082,-5.501L1.3,-5.501C-0.394,-5.501 -1.768,-4.127 -1.768,-2.433L-1.768,0.431C-1.768,2.125 -0.394,3.499 1.3,3.499L2.082,3.499C2.498,3.499 2.834,3.162 2.834,2.746L2.834,1.504C2.834,1.089 2.498,0.752 2.082,0.752L0.752,0.752C0.337,0.752 0,0.415 0,-0L0,-2.002Z" style="fill-rule:nonzero;"/>
                      </g>
                  </g>
              </g>
          </g>
      </symbol>


    </svg>

    <div class="StatusContainer">
      <Clock class="Clock" />
      <div class="Status-Icons">
        <svg><use xlink:href="#tile-chat" /></svg>
        <svg><use xlink:href="#tile-phone" /></svg>
        <svg><use xlink:href="#tile-mail" /></svg>
      </div>
      <div class="Status-BottomIcons">
        <svg><use xlink:href="#tile-phone" /></svg>
        <svg><use xlink:href="#tile-photo" /></svg>
      </div>
      <!-- <Weather class="Weather" /> -->
    </div>
    <!-- <Separator /> -->
  </div>
</template>

<script>
import Clock from './Clock.vue';
// import Icon from "./Icon.vue";

// import Weather from './Weather.vue';
// import Separator from './Separator.vue';

export default {
  name: "Status",
  components: {
    Clock,
    // Icon,
    // Weather,
    // Separator,
  },
  props: {
    screenMode: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    componentClass() {
      return "Status";

      switch (this.screenMode.mode) {
        case "asleep":
        case "waking":
          return "Status Status--large";
        case "awake":
        default:
          return "Status";
      }
    },
  },
  mounted() {
  },
}
</script>

<style scoped>
.Status {
  transition: transform 1s cubic-bezier(0.34, 1.3, 0.64, 1);
  transition-delay: 1s;
  transform-origin: top left;

}
.Status--large {
  /* transform: scale(3.5); */
}
.Status--large .StatusContainer {
  border-color: transparent;
  transition-delay: 0s;
}
.StatusContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  transition: border-color 1s ease 2s;
  /* border-bottom: var(--separator-height) solid var(--color-primary); */
  padding-bottom: .5rem;
  padding-left: 5rem;
  height: 100vh;
}

.Status-Icons {
  display: flex;
}
.Status-Icons > * {
  margin-left: 1.5rem;
  height: 2rem;
  width: 2rem;
}

.Status-BottomIcons {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  padding: 0 5rem 3rem 0;
  width: 100%;
}
.Status-BottomIcons > * {
  height:  2.5rem;
  width: 2.5rem;
}

.StatusContainer svg {
  fill: currentcolor;
}

.Clock {
  grid-area: clock;
  padding-top: clamp(5rem, 25vh, 15rem);
}

.Weather {
  display: contents;
}

</style>
