import generateMatrices from './tile-basis';

const sceneParams = {

  awake: {
    backgroundColor: "#000",
    lineColor: "#911A13",
    gridColor: "#00000000",
  },

  backgroundColor: "#000",
  clockColor: "#FEF5F2",
  colorPrimary: "#FEF5F2",

  // unused at this point
  colorSecondary: "#0F0",
  panelBackground: "#0F0",
  appsBackground: "#0F0",
  panelVibrant: "#0F0",
  panelSeparator: "#0F0",

  blackOnWhite: false,
};

const matrices = generateMatrices(sceneParams.awake.lineColor);

const theme = {
  sceneParams,
  matrices,
}
export default theme;
