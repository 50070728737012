type Mode = "asleep" | "waking" | "awake";
type CellEvent = "empty" | "sparse" | "dense";

declare var state: {
  params: any,
  setBackgroundColor: Function,
  setLineColor: Function,
  setGridColor: Function,
  resetInitialColors: Function,
};

import { FaceEvent } from "./FaceDetectionInterface";

import gsap from "gsap";

export default class ScreenMode {
  mode: Mode;
  auto: boolean;
  lockTimeout: any;
  tweens: Array<gsap.core.Tween>;

  constructor() {
    this.auto = true;
    this.mode = "asleep";
    this.tweens = [];
  }

  modeData() {
    switch(this.mode) {
      case "asleep":
      case "waking":
        return {
          duration: 5,
          background: "#000",
          line: state.params.awake.lineColor,
          grid: "#00000000",
        };
      case "awake":
        return {
          duration: 5,
          background: state.params.awake.backgroundColor,
          line: state.params.awake.lineColor,
          grid: state.params.awake.gridColor,
        };
    }
  }

  setFor(duration: number) {
    clearTimeout(this.lockTimeout);
    if (!this.auto) {
      return;
    }
    this.lockTimeout = setTimeout(() => {
      this.setMode("asleep", 5);
    }, duration * 1000);
  }
  wakeFor(options: { duration: number, transition: number }) {
    const { duration, transition } = options;
    this.setMode("awake", transition);
    this.setFor(duration);
  }
  raiseFor(options: { duration: number, transition: number }) {
    const { duration, transition } = options;
    if (this.mode === "awake") {
      return;
    }
    this.setMode("waking", transition);
    this.setFor(duration);
  }

  faceEvent(faces: FaceEvent) {
    if (!this.auto) { return; }
    if (faces === "close") {
      this.wakeFor({ duration: 20, transition: 5 });
    }
  }
  cellEvent(cells: CellEvent) {
    if (!this.auto) { return; }
    if (cells !== "dense") {
      return;
    }
    if (this.mode === "awake") {
      this.wakeFor({ duration: 20, transition: 1 });
      return;
    }
    this.raiseFor({ duration: 5, transition: 1 });
  }
  tapEvent() {
    // this.wakeFor({ duration: 20, transition: 1 });
    if (this.mode !== "asleep") {
      this.setMode("asleep", 5);
    } else {
      this.setMode("awake", 5);
    }
  }

  setMode(mode: Mode, duration = 2) {
    // eslint-disable-next-line no-param-reassign
    if (mode === this.mode) { duration = 0; }

    // console.log("previous mode:", this.mode, "new mode:", mode);
    this.mode = mode;
    this.update({ duration });
  }
  setAuto(auto: boolean) {
    this.auto = auto;
    if (!this.auto) {
      clearTimeout(this.lockTimeout);
      this.lockTimeout = null;
    }
  }
  toggleAuto() {
    this.setAuto(!this.auto);
  }

  update(options: { duration: number | undefined }) {
    const { duration } = options;
    const data = this.modeData();
    this.killTweens();
    this.tweens = [
      this.animateColorChange(state.setBackgroundColor, data.background, duration || data.duration),
      this.animateColorChange(state.setLineColor, data.line, 0.33),
      // this.animateColorChange(state.setGridColor, data.grid, duration || data.duration),
    ];
  }

  killTweens() {
    this.tweens.forEach(t => t.kill());
    state.resetInitialColors();
  }
  animateColorChange(fn: Function, val: any, duration = 3) {
    const update = function(amount: { t: number }) {
      fn.bind(state)(val, amount.t);
    };
    const lerpAmount = { t: 0 };
    return gsap.to(lerpAmount, {
      t: 1,
      duration,
      onUpdateParams: [lerpAmount],
      onUpdate: update,
    });
  }
}
